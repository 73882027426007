.jumbo {

	@extend %gdfancybg--primary;
	color: $white;

	@include media-breakpoint-up(lg) {
		.jumbo_marketing { padding-right: 5px; }
		.jumbo_conversion { padding-left: 5px; }
	}

}

	.jumbo_marketing {

		> *:first-child { margin-top: 0; }
		> *:last-child { margin-bottom: 0; }

		h1 {
			&:before { content: "Need Help With "; }
			&:after { content: "?"; }
		}

		p, ul { font-size: 1.25rem; }

		ul > li:not(:last-child) {
			margin-bottom: 0.5em;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 1rem;
		}

		@include media-breakpoint-up(md) {
			p { font-size: 1.35rem; }
		}

		@include media-breakpoint-up(lg) {
			h1 { font-size: 2.25rem; }
			ul > li { font-size: 1.3rem; }
		}

	}

	.jumbo_conversion {

		.form_quote .form_title {
			line-height: 1em;
			background-color: $color-secondary;
			@include gradient-horizontal( darken($color-secondary, 8%), lighten($color-secondary, 7.5%), 0%, 75% );
		}

	}