.title_branding {
	h1 {
		margin: 0;
		font-size: 16px;
		line-height: 1em;
	}
}

	.branding_logo {

		display: block;
		width: 275px;
		height: 29px;
		margin: 0 auto;
		font-size: 16px;
		text-indent: -9999px;
		background-image: url("/_/images/layout/logo.png");
		background-size: cover;

		@include media-breakpoint-up(lg) {
			margin-left: 0;
			width: 360px;
			height: 38px;
		}

	}