$site_info_bg: 			$color-primary;
$site_info_color: 		$white;


.site_info {
	margin-top: 1em;
	border-top: 5px solid $white;
	box-shadow: 0 -2px 5px 0 rgba(0,0,0,0.5);
	background-color: $site_info_bg;
	color: $site_info_color;
	font-size: 0.9em;
}
